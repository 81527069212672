import { defineStore } from 'pinia'
import { notification } from 'ant-design-vue'
import { validationService } from '@/services/endpoints/validations.js'
import { getFriendlyDateTime } from '@/mixins/n-time'
import _ from 'lodash'
import useConfigStore from '@/store/modules/config'
import { ref } from 'vue'

export default defineStore('validation', () => {
  const pathwayValidationHistory = ref([])
  const pathwayValidationStatuses = ref([])
  const classifierValidationHistory = ref([])
  const classifierValidationStatuses = ref([])
  const validationMeta = ref({
    approve: {
      longName: 'Approved',
      explanation: 'Validation has been Approved',
      icon: 'check',
      colour: 'green',
    },
    reject: {
      longName: 'Rejected',
      explanation: 'Validation has been Rejected',
      icon: 'times',
      colour: 'red',
    },
    add: {
      longName: 'Added / Created',
      explanation: 'A new validation has been created on the document',
      icon: 'plus',
      colour: 'blue',
    },
    change: {
      longName: 'Changed',
      explanation: 'Validation has been Changed',
      icon: 'sync',
      colour: 'orange',
    },
    pathway: {
      longName: 'Pathway',
      explanation: 'Validation on a Pathway',
      icon: 'project-diagram',
      background: 'has-background-grey-dark',
    },
  })
  const rovaValidationStatusColours = ref({
    approve: '#00db3a',
    reject: '#c20000',
    change: '#4da6ff',
  })

  const validationHistory = ref([])

  const clearValidationData = () => {
    pathwayValidationHistory.value = []
    pathwayValidationStatuses.value = []
    classifierValidationHistory.value = []
    classifierValidationStatuses.value = []
    validationHistory.value = []
  }

  const getAllValidationHistory = () => {
    return validationHistory.value
  }

  const loadAllValidationHistory = async (PTLUniqueIDs) => {
    // Loads all validation history for a list of PTLUniqueIDs
    validationHistory.value = []
    await getPathwayValidationHistory(PTLUniqueIDs)
    await getClassifierValidationHistory(PTLUniqueIDs)
  }

  const getPathwayValidationStatuses = async () => {
    // Gets the status by finding the latest value for each PTLUniqueID
    const statuses = []

    for (const history of pathwayValidationHistory.value) {
      const existingStatus = statuses.find((s) => s.PTLUniqueID === history.PTLUniqueID)
      if (existingStatus) continue

      statuses.push(history)
    }

    pathwayValidationStatuses.value = statuses
    return statuses
  }

  const getPathwayValidationHistory = async (PTLUniqueIDs) => {
    const history = await validationService.getValidationHistory(PTLUniqueIDs)
    pathwayValidationHistory.value = history
    validationHistory.value.push(...history)
    getPathwayValidationStatuses()
    return history
  }

  const getClassifierValidationHistory = async (PTLUniqueIDs) => {
    const history = await validationService.classificationHistory(PTLUniqueIDs)
    classifierValidationHistory.value = history

    // Get the latest validation for each classifier
    const latestValidation = []
    for (const classifier of history) {
      const existingValidation = latestValidation.find(
        (v) => v.ClassificationId === classifier.ClassificationId,
      )
      if (existingValidation) continue

      latestValidation.push(classifier)
    }

    validationHistory.value.push(...latestValidation)
    return history
  }

  const filterClassificationHistory = (classificationId) => {
    // Loads the classification history for a single classifier
    // and parses it to return values for the table
    return classifierValidationHistory.value
      .filter((h) => h.ClassificationId === classificationId)
      .map((h, index) => ({
        key: index,
        ValidatedBy: h.ValidatedBy,
        ValidationType: _.capitalize(h.ValidationType),
        ValidationComment: constructComment(h),
        ValidatedOn: getFriendlyDateTime(h.ValidatedOn),
        ValidationRejectionReason: h.ValidationRejectionReason,
      }))
  }

  const handleValidation = async ({ payload, validationType, successMessage }) => {
    try {
      const validation = await validationService[validationType](payload)
      classifierValidationHistory.value.unshift({
        ...validation,
        ValidatedOn: new Date().toISOString(),
      })
      notification.success({
        message:
          successMessage || !['add', 'reject'].includes(validationType)
            ? `Classification ${_.capitalize(validationType)}d`
            : `Classification ${_.capitalize(validationType)}ed`,
      })
      return validation
    } catch (error) {
      notification.error({
        message: `${_.capitalize(validationType)} Classification Error`,
        description: `Something went wrong whilst trying to ${validationType} the classification`,
      })
    }
  }

  const rejectValidation = (payload) => {
    return handleValidation({
      payload,
      validationType: 'reject',
    })
  }

  const changeValidation = (payload) => {
    return handleValidation({
      payload,
      validationType: 'change',
    })
  }

  const createValidation = (payload) => {
    return handleValidation({
      payload,
      validationType: 'add',
    })
  }

  const approveValidation = (payload) => {
    return handleValidation({
      payload,
      validationType: 'approve',
      successMessage: 'Classification Approved',
    })
  }

  const constructComment = (record) => {
    const comment = record.ValidationComment
      ? `Comment: <strong>${record.ValidationComment}</strong>`
      : ''
    return comment
  }

  const getContextOptions = (type, hasPermission) => {
    const configStore = useConfigStore()
    // NOTE: Send the object key to database.
    // Need to add parent categories that are missing

    // Get Web Config Objects
    const webConfig = configStore.getConfigByKey('web-config')
    const contextOptions = webConfig['context-menus'][type]
    const classifierValues = Object.entries(webConfig.documents.classifiers).map(
      ([key, values]) => {
        return {
          key,
          ...values,
        }
      },
    )

    // Override showOnValidationMenu for viewAll permission
    classifierValues.forEach((c) => {
      // Removes classifier from menu if it's not active
      if (c.active === false) {
        c.showOnValidationMenu = false
        return
      }

      if (hasPermission) {
        c.showOnValidationMenu = true
      }
    })

    // List of unique (not null) parent caregories
    const parentCategories = [
      ...new Set(
        classifierValues.filter((c) => c.showOnValidationMenu).map((item) => item.parentCategory),
      ),
    ].filter((category) => category)

    // Get the 'change' filter menu
    for (const index in contextOptions) {
      if (!contextOptions[index].options) {
        // eslint-disable-next-line no-continue
        continue
      }
      // Find children associated with parent categories and construct child json
      contextOptions[index].options = parentCategories.map((parent) => {
        const options = classifierValues.filter(
          (c) => c.parentCategory === parent && c.showOnValidationMenu,
        )

        return {
          value: parent,
          options,
        }
      })
    }

    return contextOptions
  }

  const getValidationvalidationMeta = (validationType) => {
    return validationMeta.value[validationType]
  }

  return {
    pathwayValidationHistory,
    pathwayValidationStatuses,
    classifierValidationHistory,
    classifierValidationStatuses,
    validationMeta,
    rovaValidationStatusColours,
    validationHistory,
    getAllValidationHistory,
    loadAllValidationHistory,
    filterClassificationHistory,
    handleValidation,
    rejectValidation,
    changeValidation,
    createValidation,
    approveValidation,
    constructComment,
    getContextOptions,
    getValidationvalidationMeta,
    clearValidationData,
  }
})
