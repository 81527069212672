const envConfig = {
  'localhost:8080': { apiUrl: 'http://localhost:9992/api/' },
  '0.0.0.0:8080': { apiUrl: 'http://localhost:9992/api/' },
  'localhost:8081': { apiUrl: 'http://localhost:9992/api/' },
  // WSH Training
  'surveyor.lunatest.wsha.local:8443': { apiUrl: 'https://surveyor.lunatest.wsha.local:9991/api/' },
  // WSH Test
  'surveyor.lunatest.wsha.local:9443': { apiUrl: 'https://surveyor.lunatest.wsha.local:9992/api/' },
  // UHS Training
  'surveyor.lunasandbox.uhs.nhs.uk:8443': {
    apiUrl: 'https://surveyor.lunasandbox.uhs.nhs.uk:9991/api/',
  },
  // HDFT Training
  'surveyor.lunasandbox.hhc-tr.northy.nhs.uk:8443': {
    apiUrl: 'https://surveyor.lunasandbox.hhc-tr.northy.nhs.uk:9991/api/',
  },
  // HDFT Test
  'surveyor.lunasandbox.hhc-tr.northy.nhs.uk:9443': {
    apiUrl: 'https://surveyor.lunasandbox.hhc-tr.northy.nhs.uk:9992/api/',
  },
  // RFL Training
  'surveyor.lunasandbox.royalfree.nhs.uk:8443': {
    apiUrl: 'https://surveyor.lunasandbox.royalfree.nhs.uk:9991/api/',
  },
  // RFL Test
  'surveyor.lunasandbox.royalfree.nhs.uk:9443': {
    apiUrl: 'https://surveyor.lunasandbox.royalfree.nhs.uk:9992/api/',
  },

  // BHT Training
  'surveyor.lunasandbox.bartshealth.nhs.uk:8443': {
    apiUrl: 'https://surveyor.lunasandbox.bartshealth.nhs.uk:9991/api/',
  },
  // BHT Test
  'surveyor.lunasandbox.bartshealth.nhs.uk:9443': {
    apiUrl: 'https://surveyor.lunasandbox.bartshealth.nhs.uk:9992/api/',
  },
}

// Get base domain from hos
const baseDomain = window.location.host.split('.').slice(1).join('.')

export const apiUrl =
  envConfig[window.location.host]?.apiUrl || `https://api.${baseDomain}/surveyor/api/`

export default apiUrl
