import config from '@/config'
import acquireAzToken from 'mbi.luna.apps.aad/dist/frontend/acquireAzToken'

export const getAuthToken = async (options = {}, force = false) => {
  if (config.authMode === 'azure' || force) {
    // Get username from query params
    const params = new Proxy(new URLSearchParams(window.location.search), {
      get: (searchParams, prop) => searchParams.get(prop),
    })

    const defaultOptions = {
      reloadOnNewToken: true,
      acquireTokenRedirect: true,
      username: params.User,
    }

    const token = await acquireAzToken(config.aad, { ...defaultOptions, ...options })
    return token.bearerToken
  }
}

export default getAuthToken
